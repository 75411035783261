export namespace UtilsStr {
  export function FormatDecimalToLocaleString(s: string, decimals: number = 2): string {
    const n = parseFloat(s);
    if (!n) return '[não definido]';
    return n.toLocaleString('pt-BR', {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    });
  }

  export function FormatDateIso8601ToLocaleString(isoDate: string): string {
    // Entra: 2021-06-21Z
    // Sai: 21/06/2021
    if (!isoDate) return '[não definido]';
    const date = new Date(isoDate);
    return date.toLocaleDateString('pt-BR', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  }

  export function FormatStringNull(s: string): string {
    if (!s) return '[não definido]';
    return s;
  }

  export function FormatArrayList(s: string[]): string {
    const formatter = new Intl.ListFormat('pt-br');
    return formatter.format(s);
  }

  export function TiraLixo(str: string): string {
    return str && str.length > 0
      ? str
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
      : null;
  }
}
